@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.graph {
  &.graph-positive {
    color: $surf-positive;
    
    .bar-line {
      background-color: $surf-positive;
    }
  }
  &.graph-negative {
    color: $surf-negative;

    .bar-line {
      background-color: $surf-negative;
    }
  }
  &.graph-zero {
    color: $surf-text-dark-neutral;
  }
  .bar {
    width: 40px;
    height: 4px;
    border-radius: 2px;
    margin-left: .5rem;
    background-color: rgba($surf-background-dark-secondary, 0.75);
    position: relative;

    .bar-line {
      height: 4px;
      border-radius: 2px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}