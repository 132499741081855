@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';


.dropdown {
  position: relative;
  overflow1: hidden;
  
  button {
    flex-grow: 1;
    width: 100%;
  }

  &.nav-dropdown {
    & > a {
      color: white;
      @include transition(0.15s);

      &:after {
        content: none;
      }

      &:hover {
        &, &:focus, &:active, &:hover {
          color: rgba($white, .7);
        }
      }

      &, &:focus, &:active, &:hover {
        outline: none;
      }
      &[aria-expanded="true"] {
        color: transparent;
        @include transition(0.05s);
      }
    }
    [aria-disabled="true"], .disabled {
      &, &:focus, &:active, &:hover {
        color: rgba($white, .3);
        cursor: default;
      }
    }
    .disabled {
      color: rgba($white, .3);
    }
    & > div {
      background-color: transparent;
      box-shadow: none;
      border: none;
      margin-top: 0;
      margin: 0;
      top: -.25rem;
      left: -.5rem;
      box-shadow1: 0px 32px 48px rgba(22, 26, 29, 0.5);
      padding: .75rem .75rem 0;
      background1: lighten($dark-alt, 0%);
      border-radius: 1rem;

      &, * {
        color: white;
      }

      & > * {
        margin-bottom: 1rem;
        padding: 0 .25rem;
        border-radius: .125rem;



        &:focus, &:focus-visible {
          &, &:focus, &:active, &:hover {
            background-color: transparent;
            border-width: 0;
            outline: 2px solid rgba($main, 0.5);
          }
        }
        &:active {
          &, &:focus, &:active, &:hover {
            background-color: transparent;
            color: rgba($white, .7);
            outline: none;
          }
        }
        &:hover {
          &, &:focus, &:active, &:hover {
            background-color: transparent;
            color: rgba($white, .7);
          }
        }
      }
    }
  }
  &.dropdown-disabled {
    button {
      background-color: $surf-background-dark-tertiary !important;
      border-color: $surf-background-dark-tertiary !important;
      pointer-events: none !important;
      color: $gray !important;
      cursor: default !important;
    }
  }
  &.dropdown-open {
    overflow: visible;
  }
  &.dropdown-size-small {
    .dropdown-menu {
      top: 44px;
    }
  }
  &.dropdown-size-medium {
    .dropdown-menu {
      top: 56px;
    }
  }
  &.dropdown-size-large {
    .dropdown-menu {
      top: 68px;
    }
  }

  .dropdown-menu {
    position: absolute;
    z-index: 10;
    top: 60px;
    opacity: 0;
    pointer-events: none;
    color: $white;
    transform: translateY(-50px);
    max-height: 360px;
    overflow: scroll;

    box-shadow: none;
    border: none;
    margin-top: 0;
    margin: 0;
    left: 0;
    min-width: 100%;
    min-height: 88px;
    box-shadow: 0px 32px 48px rgba(22, 26, 29, 0.5);
    padding1: .75rem .75rem;
    background: desaturate(lighten($secondary, 5.5), 0);
    border-radius: .625rem;
    transition: transform .15s cubic-bezier(0.375, 0.885, 0.6, 1) 0.3s, opacity .2s cubic-bezier(0.375, 0.885, 0.6, 1) 0s, pointer-events 0s cubic-bezier(0.375, 0.885, 0.6, 1) 0.2s, visibility 0s cubic-bezier(0.375, 0.885, 0.6, 1) 0.2s;

    &.dropdown-menu-open {
      opacity: 1;
      visibility: visible;
      pointer-events: all;

      transition: transform .3s cubic-bezier(0.375, 0.885, 0.6, 1) 0.02s, opacity .3s cubic-bezier(0.375, 0.885, 0.6, 1) 0.05s, pointer-events 0s cubic-bezier(0.375, 0.885, 0.6, 1) 0.01s, visibility 0s cubic-bezier(0.375, 0.885, 0.6, 1) 0.01s;
      transform: translateY(0);
    }
    &::-webkit-scrollbar {
      display: none;
    }
    
    /* Hide scrollbar for IE, Edge and Firefox */
    & {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }

    .dropdown-option {
      padding: .5rem 1.5rem .5rem 1.25rem;
      white-space: nowrap;
      cursor: pointer;
      vertical-align: middle;
      font-size: 15px;
      line-height: 1.75rem;

      .dropdown-option-icon {
        margin-right: .5rem;
        &, * {
          vertical-align: middle;
          display: flex;
          height: 24px;
          width: 24px;
        }
      }

      &:first-child {
        padding-top: .75rem;
        padding-bottom: .625rem;
      }

      &:last-child {
        padding-top: .625rem;
        padding-bottom: .75rem;
      }
      & > span {
        margin-right: 1rem;
        vertical-align: middle;
        margin-top: -1px;
        font-size: 16px;
      }
      &:hover {
        background-color: rgba($white, 0.05);
      }
      &.dropdown-option-disabled {
        color: rgba($white, 0.3);
        cursor: default;

        &:hover {
          background-color: inherit;
        }
      }
    }
  }
}