@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.modal-overlay {
        padding-left: 0 !important;
        padding-right: 15px !important;
    & > * {
        & > * {
            background-color: $surf-background-dark-primary !important;

            & > * {
                padding: 0 !important;
            }
        }
    }
    .modal-header {
        padding: 2rem !important;
    }
}
.modal-overlay-backdrop {
    background-color: transparent !important;
}

.modal {
    padding-right1: 0 !important;
    & > * {
        & > * {
            background-color: $surf-background-dark-primary !important;
            border-radius: .75rem !important;
            padding1: 1rem !important;

            &  {
                & > * {
                    border: none !important;
                }
            }
        }
    }
}