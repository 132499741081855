@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.content-container {
  width: 380px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  h1 {
      margin-bottom: 2rem;
  }
  .stage-panel {
    margin-bottom: 1rem;

    .stage-panel-row {
      border-bottom: 1px solid rgba($white, 0.07);
      min-height: 56px;
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important;

      & > div:first-child {
        color: $surf-text-dark-secondary;
      }
      & > div:last-child {
        word-break: break-all;
      }

      &:last-child {
        border-bottom: none;
        padding-bottom: 0 !important;
      }
    }
  }
  &.content-container-center {
    align-items: center;

    h3 {
      margin-bottom: 2rem;
    }
    .qrcode {
      margin-bottom: 1rem;
      color: $surf-text-dark-secondary;
    }
  }
}
.action-confirmed {
  .content-container {
    .qrcode {
      width: 256px;
      height: 256px;
      background-color: $surf-background-dark-primary-inverted;
      border-radius: .75rem;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 212px;
        height: 212px;
      }
    }
    .qrcode-desc {
      white-space: pre;
      margin-bottom: 1.5rem;
    }
    button {
      margin-bottom: 1rem;
      max-width: 256px;
    }
  }
}