$surf-blue: #0083e0;
$surf-cobalt: #3888FF;
$surf-pink: #FF7B86;
$surf-yellow: #F1CD5E;
$surf-green: #88BF26;


$tertiary: #b6b8ba;

$white: #FFFFFF;
$surf-gray-background: #fafafc;
$surf-gray-background-alt: #f4f4f5;
$dark: #1C2125;
$dark-alt: #20262A;

$dark-primary: #20262A;
$surf-gray: #20262A;
$surf-gray-2: #707376;
$surf-gray-3: #6b707e;
$surf-gray-4: #a2a9b9;

$tertiary: #b6b8ba;
$gray-light: #8c8f91;
$gray-extralight: #ADB0B1;

$text-secondary: #adb0b1;


$dark: #1C2125;
$dark-alt: #20262A;
$gray: #676B6E;
$tertiary: #b6b8ba;
$gray-light: #8c8f91;
$gray-extralight: #ADB0B1;
$white: #ffffff;
$green-alt: #2FA851; //#36C05C;
$green: #36C05C;
$surf-positive: #36c05c;
$surf-negative: #e71717;
$surf-accent: #0083e0;
$surf-warning: #ffc043;
$blue: #0083E0;
$red: #e22626;
$white: #FFFFFF;
$surf-gray-background: #fafafc;
$surf-gray-background-alt: #f4f4f5;

// ui-kit-colors
$surf-cerise: #E33F84;
$surf-red-violet: #A62E99;
$surf-purple-heart: #681EB0;
$surf-daisy-blush: #4E1AA6;
$surf-blue-gem: #35179C;
$surf-governor-bay: #3D3BC1;
$surf-royal-blue: #4963E6;
$surf-cornflower-blue: #5B95E8;
$surf-blue-sky: #70C7EC;

$surf-positive: #36c05c;
$surf-negative: #e71717;
$surf-accent: #0083e0;
$surf-warning: #ffc043;

$surf-text-dark-primary: #fdfdfd;
$surf-text-dark-secondary: #adb0b1;
$surf-text-dark-tertiary: #676b6e;
$surf-text-dark-neutral: #3b4043;

$surf-background-darken: #1C2125;
$surf-background-dark-primary: #20262a;
$surf-background-dark-primary-inverted: #fdfdfd;
$surf-background-dark-secondary: #292f32;
$surf-background-dark-tertiary: #32373B;
$surf-background-dark-neutral: #3b4043;

$surf-background-dark-bw: #1C2125;
$surf-dark-text-neutral: #3B4043;


// scss-docs-start gray-color-variables
$white:    #fff;
$gray-100: #fafafc;
$gray-200: #e9eaef;
$gray-300: #dedfe6;
$gray-400: #ced2da;
$gray-500: #adb3bd;
$gray-600: #6c717d;
$gray-700: #494d57;
$gray-800: #343840;
$gray-900: #212329;
$black:    #000;


// Create your own map
$surf-colors: (
  "blue": $surf-blue,
  "red": $surf-pink,
  "green": $surf-green,
  "cyan": rgb(176, 225, 233),
  "yellow": rgb(241, 205, 94)
);



$main: $surf-royal-blue;

$primary: $main;
$secondary: $dark;
$success: #1c9452;
$failure: #E71717;