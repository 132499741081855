@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.card-basic {
  .address {
    margin-left: .5rem;
  }
  .card-basic-left {
    & > * {
      margin-bottom: 4px;
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }
}