@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.content-container {
  width: 380px;
  margin: 0 auto;

  @media (max-width: 575px) {
    width: auto;
  }

  h1 {
      margin-bottom: 2rem;
  }
  .vesting-item {
    margin-bottom: 1rem;
    color: $surf-text-dark-primary;
  
    .address {
      margin-left: .5rem;
    }
  }
  .skeleton-up {
    transform: translateY(-7px);
  }
  .skeleton-up-alt {
    transform: translateY(-2px);
  }
  .skeleton-down {
    transform: translateY(7px);
  }
}
