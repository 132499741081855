@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.header {
  position: relative;

  a {
    svg {
      vertical-align: middle;
    }
  }
  
  .navbar {
    padding: 2rem 6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 120px;

    @media (max-width: 1199px) {
      padding: 2rem 4rem;
    }
    @media (max-width: 991px) {
      padding: 2rem 2rem;
    }
    @media (max-width: 767px) {
      padding: 2rem 2rem;
    }
    @media (max-width: 575px) {
      padding: 2rem 2rem;
      min-height: 120px;
    }
    

    .navbar-nav {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: 767px) {
        justify-content: space-between;
        width: 100%;
      }
    }

    .navbar-brand {
      position: relative;
      padding-left: .75rem;
      padding-right: 1rem;
      padding-top: 0;
      padding-bottom: 0;
      cursor: default;
      white-space: nowrap;

      @media (max-width: 575px) {
        padding-left: 0.25rem;
      }

      a {
        font-size: 1rem;
        font-weight: 600;
        color: $white;
        display: block;
        padding: 0.45rem; 
        padding-left: 0;
        border-radius: .5rem;

        svg {
          vertical-align: middle;
          margin-right: 5px;
          margin-left: 5px;
          padding-left: 0;
          transform: translateY(-2px);
        }
      }
    }

    .navbar-collapse {
      flex-grow: 1;
      width: calc(100% - 1rem);
      color: $white;
      position: absolute;
      left: .5rem;
      top: .5rem;
      z-index: 1100;
      background: rgba(darken($dark, 0), 1);
      border-radius: 1rem;
      padding: 4rem 0 .825rem;

      @include transition(0.3s);

      @media (max-width: 575px) {
        padding: 4rem .5rem 1rem;
      }

      &.animated {
        @include transition(0.3s);
        background: rgba(darken($dark, 0), 0);
      }

      .navbar-nav {
        flex-grow: 1;
        @include transition(0.15s);
        box-shadow: 0px 0px 0px 0 rgba(33,39,56,0.01);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        @media (max-width: 767px) {
          margin-top: 2px;
          @include transition(0.35s);
        }

        & > * {
          margin-left: .7rem;

          @media (max-width: 575px) {
            margin-left: 0;
          }
        }
        
      }
      
    }
    .surf-toggle {
      z-index: 1060;
    }
    .nav-item {
      & > a {
        & {

          &:after {
            content: none;
          }

          sup {
            color: $surf-green;
            color: #2FA851;
          }

          @media (max-width: 575px) {
            padding: 1rem 0.25rem;
            position: relative;

            &:after {
              position: absolute;
              top: 1.25rem;
              right: 0.5rem;
            }
          }
        }
      }
      &:not(.show) {
        a {
          & {
            @media (max-width: 575px) {
              // border-bottom: 1px solid rgba($SURF_TERTIARY, 0.2);
            }
          }
        }
      }
      &.navbar-dropdown {
        margin-right: 2rem;
      }
    }

    a {

      &.nav-link, &.dropdown-item {
        color: $white;
        font-weight: 600;
        padding: 1rem 1rem;

        &:hover, &.active {
          color: rgba($white, 0.6);
        }
        svg {
          vertical-align: middle;
          width: 1rem;
          height: 1rem;
          margin-top: -1px;
        }
        &.nav-link-default {
          &:hover, &:focus, &:active {
            &:hover, &:focus, &:active {
              color: rgba($white, 0.7);
            }
          }
        }
        &.disabled {
          pointer-events: none;
          color: rgba($white, 0.3);
        }
      }
    }
    .navbar-toggler {
      border: none;
      position: relative;
      width: 54px;
      height: 40px;
      z-index: 2;
      margin-right: 0.25rem;
      z-index: 100;

      &, &:hover, &:focus, &:active {
        &, &:hover, &:focus, &:active {
          border: none;
          box-shadow: none;
          outline: none;
        }
      }

      span {
        background-image: none;
        display: none;
      }

      &:before, &:after {
        content: '';
        height: 2px;
        width: 24px;
        background-color: $gray;
        position: absolute;
        @include transition(0.15s);
        left: 15px;
      }
      &:before {
        top: 15px;
      }
      &:after {
        top: 21px;
      }

      &:not(.collapsed) {
        &:before {
          transform: rotate(45deg) translateY(2px) translateX(2px);
        }
        &:after {
          transform: rotate(-45deg) translateY(-2px) translateX(2px);
        }
      }

      &:hover {
        &:before, &:after {
          background-color: $main;
          @include transition(0.35s);

        }
      }
    }
  }
  .header-panel {
    & > *:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
  .everscale {
    color: $surf-text-dark-tertiary;

    @media (max-width: 767px) {
      display: none;
    }

    svg {
      transform: translateY(2px) scale(1.2);
      margin: 0 3px;
    }
  }
}
